.container {
    border-top: 1px solid #d8d8d8;
    margin-top: 40px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 1050px;
    margin: 0 auto;
    gap: 10px;
    border-bottom: 1px solid rgba(196, 36, 65, 0.2);
}
.header-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 50px;
    padding: 0 24px;
    font-size: 14px;
    line-height: 16px;
    color: rgba(40, 48, 63, 0.7);
    animation: header-item-animation-output 0.3s;
    background: #fff;
    /* flex: 1; */
    border: none;
    border-radius: 10px 10px 0px 0px;
}
.header-item:hover {
    animation: header-item-animation-input 0.3s;
    color: #C42441;
    background: rgba(196, 36, 65, 0.1);
    border-radius: 10px 10px 0px 0px;
}

.header-item-active {
    color: #C42441;
    animation: none;
    background: rgba(196, 36, 65, 0.1);
    border-radius: 10px 10px 0px 0px;
    position: relative;
}
.header-item-active::after {
    content: "";
    position: absolute;
    height: 4px;
    background: #C42441;
    left: 0;
    right: 0;
    bottom: 0;
}

.header-item-active:hover {
    color: #C42441;
    animation: none;
}
@keyframes header-item-animation-input{
    0%{
        color: rgba(40, 48, 63, 0.7);
        background: #fff;
    }
    100%{
        color: #C42441;
        background: rgba(196, 36, 65, 0.1);
    }
}

@keyframes header-item-animation-output{
    0%{
        color: #C42441;
        background: rgba(196, 36, 65, 0.1);
    }
    100%{
        color: rgba(40, 48, 63, 0.7);
        background: #fff;
    }
}


.action {
    width: 1050px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 6px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.action-title {
    font-size: 14px;
    line-height: 16px;
    color: rgba(40, 48, 63, 0.7);
    border-right: 1px solid rgba(40, 48, 63, 0.7);
    padding-right: 20px;
    margin: 0 6px 0 24px;
}

.action > div {
    color: #fff;
    border: none;
    position: relative;
    padding: 0 14px;
    cursor: pointer;
}

.list {
    width: 1050px;
    margin: 0 auto;
}

.columns-rating {
    display: flex;
}

.columns-link {
    min-width: 165px;
    cursor: pointer;
}
.columns-link:hover {
    color: #AD0F25;
}

.columns-document {
    display: flex;
    align-items: center;
    justify-content: center;
}
.columns-document >img {
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: pointer;
}
.columns-document >img:hover {
    background: #2f2f2f
}

.steps-container {
    transform: translateY(-16px);
}
.steps-second-container {
    transform: translateY(16px);
}

.header-mobile {
    display: none;
}

@media (max-width: 1280px) {
    .header {
        width: auto;
        padding: 0 10px;
    }
    .action {
        width: auto;
        margin: 24px 0 0 0;
    }
    .list {
        width: auto;
        margin: 0;
        padding: 0 10px;
    }
    .columns-link-active:hover {
        color: #fff;
    }
}

@media (max-width: 767px) {
    .container {
        padding-top: 0;
    }
    .header {
        width: calc(100% - 20px);
        display: flex;
        flex-direction: column;
        display: none;
    }
    .columns-link {
        display: flex;
        flex-direction: column;
        text-align: end;
    }

    .header-mobile {
        position:  fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        box-shadow: 30px 30px 30px 30px rgba(65, 75, 99,0.1);
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        gap: 4px;
        z-index: 999;
    }
    .mobile-header-item {
        font-size: 10px;
        display: flex;
        flex-direction: column;
        align-items:  center;
        text-align: center;
        justify-content: center;
        padding: 12px 0;
        background: #fff;
        border: none;
        /* border: 1px solid red; */
    }
    .mobile-header-item-active {
        color: #ffffff;
        background: #bc0021;
        border-radius: 4px;
        position: relative;
    }
    .mobile-header-item-label {
        /* overflow:hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        border: 1px solid blue; */
    }
    /* .mobile-header-item-active::before {
        content: "";
        position: absolute;
        height: 10px;
        width: 10px;
        background: #bc0021;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        top: -4px;
        border-radius: 2px;
    } */
}