.container {
    width: 1050px;
    margin: -18px auto 0 auto;
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 80px;
}
.header-top {
    height: 25px;
    border: 1px solid #d6d7d6;
    background: #ca2d2d;
    /* width: 100%; */
}
.header-top > h1 {
    margin: 0;
    font-size: 14px;
    line-height: 25px;
    font-weight: 700;
    color: #fff;
    padding-left: 16px;
}
.header-body {
    display: flex;
    justify-content: space-between;
    margin: 14px 0;
    position: relative;
}
.header-body-title {
    font-size: 24px;
    line-height: 1.1;
    margin: 0;
    color: #333333;
    word-wrap: break-word;
    width: 70%;
    position: relative;
}
.header-body-title-tips {
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    font-size: 12px;
    color: red;
    font-weight: 400;
}
.header-body-link {
    padding: 10px;
    position: absolute;
    right: 0;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: #bc0021;
    border-radius: 8px;
}
.header-body-link-bg {
    position: absolute;
    right: 0;
    left: 0;
    height: 44px;
    border-radius: 8px;
    background: #bc0021;
    z-index: -1;
    animation: pulse 1.2s ease infinite;
}
@keyframes pulse {
    0% {
      transform: scale(1, 1);
    }
    50% {
      opacity: 0.2;
    }
    100% {
      transform: scale(1.1, 1.3);
      opacity: 0;
    }
}
.header-body-link-disabled {
    opacity: 0.4;
    cursor: not-allowed;
}
.header-body-link > img {
    margin-right: 6px;
    height: 24px;
    width: 24px;
    font-weight: 700;
}

.header-body-group-title {
    display: none;
}


.header-info {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
    color: #333333;
}
.header-info-item {
    display: flex;
    gap: 8px;
    padding-left: 20px;
    position: relative;
    align-items: center;
    margin-top: 8px;
}
.header-info-item:first-child {
    margin-top: 0;
}
.header-info-item-round {
    position: absolute;
    height: 5px;
    width: 5px;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%)
}

.tabs {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
}
.tabs-item {
    background: #ffffff;
    border: none;
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    border-radius: 10px;
    font-weight: 700;
}
.tabs-item >img {
    margin-right: 6px;
    width: 24px;
    height: 24px;
}
.tabs-item:hover {
    color: #bc0021;
}
.tabs-item-active {
    color: #ffffff;
    background: #bc0021;
}
.tabs-item-active:hover {
    color: #ffffff;
}

.mobile-tabs {
    display: none;
}

@media (max-width: 1280px) {
    .container {
        width: auto;
        padding: 0 20px;
    }
    .tabs {
        flex-wrap: wrap;
    }
}

@media (max-width: 767px) {
    .header-body {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    .header-body-link {
        position: relative;
        width: 95px;
        padding: 10px 8px;
    }
    .header-body-title {
        width: 100%;
    }
    .header-info-item  {
        display: grid;
        grid-template-columns: 25% 75%;
    }
    .tabs {
        display: none;
    }
    .mobile-tabs {
        position:  fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: #fff;
        box-shadow: 30px 30px 30px 30px rgba(65, 75, 99,0.1);
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        /* grid-template-columns: repeat(7, minmax(0, 1fr)); */
        gap: 4px;
        z-index: 999;
    }
    .mobile-tabs-item {
        /* border: 1px solid red; */
        font-size: 10px;
        display: flex;
        flex-direction: column;
        align-items:  center;
        text-align: center;
        padding: 12px 0;
    }
    .mobile-tabs-item > img {
        width: 24px;
        height: 24px;
    }
    .mobile-tabs-item-active {
        color: #ffffff;
        background: #bc0021;
        border-radius: 4px;
    }
    .mobile-tabs-item-label {
        overflow:hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-wrap: break-word;
        width: 48px;
        margin-top: 4px;
        text-align: center;
    }
    .header-body-group {
        display: flex;
        align-items:center;
        margin-top: 14px;
        width: 100%;
        justify-content:  space-between;
    }
    .header-body-group-title  {
        display: block;
        font-size: 18px;
        font-weight: 600;
    }
}