.container {
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    /* gap: 24px; */
    font-size: 14px;
    line-height: 16px;
}

.title-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F0EFEF;
    font-size: 14px;
    line-height: 1.5;
    color: #333333;
    font-weight: 600;
    margin-bottom: 12px;
    border-radius: 8px;
    padding-right: 18px;
}
.title {
    padding: 2px 0 2px;
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
    color: #333333;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.title > img {
    width: 30px;
    height: 30px;
    margin-right: 6px;
}

.header {
    display: flex;
}
.header-item {
    flex: 1;
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}
.header-item:last-child {
    margin-right: 0;;
}
.header-chart {
    flex: 1;
    /* min-height: 250px; */
}

.key {
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
}
.key-tips {
    font-weight: 600;
    color: #BC0021;
    margin: 8px 0 -3px 18px;
    line-height: 1.2;
}
.key-item {
    display: flex;
    align-items: center;
    padding: 7px 18px;
    font-size: 14px;
    line-height: 1.5;
    gap: 6px;
    background: #F3F2F2;
    border-radius: 5px;
    margin-bottom: 8px;
}
.key-item:last-child {
    margin-bottom: 0;
}
.key-item-group {
    flex: 1;
}
.key-item-group-trailing {
    flex-grow: 0.4;
}

.key-item-date {
    color: #BC0021;
    font-weight: 600;
    background: #fff;
}

.footer {
    margin-top: 24px;
}

.star-box {
    display: flex;
    gap: 2px;
}


@media (max-width: 1280px) {
    .header {
        display: flex;
        flex-direction: column;
    }
    .header-item {
        width: auto;
        margin-right: 0;
    }
    .header-item {
        margin-bottom: 20px;
    }
}