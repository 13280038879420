.container {
    display: flex;
    position: relative;
}

.plus-button {
    background: transparent;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    border: 1px solid #BC0021;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #201e1d;
}
.plus-button:hover {
    background: rgba(188,0,33, 0.1);
}

.plus-button > img {
    width: 16px;
    height: 16px;
    margin-right: 12px;
}
.plus-button:first-child {
    margin-right: 48px;;
}
.plus-button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
}
.plus-button:disabled:hover {
    opacity: 0.4;
    cursor: not-allowed;
    background: #fff
}

.modal {
    position: absolute;
    height: 100px;
    left: 0;
    width: 300px;
    top: calc(100% + 16px);
    border: 1px solid rgb(204, 204, 204);
    background: #ffffff;
    border-radius: 8px;
    cursor: auto;
    padding: 16px;
    z-index: 999;
}

.modal-container {
    display: flex;
    flex-direction: column;
    position: relative;
}
.modal-container::before {
    position: absolute;
    content: '';
    width:0;
    height: 0;
    border: 10px solid transparent;
    border-bottom-color: rgb(229, 229, 229);
    left: 15px;
    top: -36px;
}

.modal-title {
    font-weight: 600;
    padding-bottom: 16px;
    border-bottom: 2px solid rgb(51, 51, 51);
    margin-bottom: 16px;
    color: #201e1d;
}

.input-container {
    height: 30px;
    padding: 0 32px 0 16px;
    background: rgb(229, 229, 229);
    border-radius: 15px;
    position: relative;
    display: flex;
    align-items: center;
}
.input {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border: none;
    outline: none;
    background: rgb(229, 229, 229);
}
.input-loading {
    position: absolute;
    top: 7px;
    right: 16px;
    width: 16px;
    height: 16px;
    border: 3px solid #666;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    display: none;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.input-left-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;;
}

.select-list {
    position: absolute;
    top: calc(100% + 4px);
    left: 6px;
    right: 6px;
    background: #fff;
    z-index: 998;
    max-height: 200px;
    padding: 15px 0;
    border: 1px solid #999999;
    border-top: 2px solid #004988;
    box-shadow: 0px 4px 8px 1px rgba(59,59,59,0.35);
    overflow: auto;
    outline: none;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 16px;
}
.select-list-title {
    color: #BC0021;
    font-weight: 700;
    margin-bottom: 8px;
    text-align: center;
}
.select-list-title-empty {
    margin: 0;
    color: #444;
}
.select-list-item {
    padding: 5px 6px 5px 20px;
    color: #444;
    cursor: pointer;
}
.select-list-item:hover {
    background: #BC0021;
    color: #FFFFFF;
    font-weight: 600;
}

.select-list-loader {
    color: #BC0021;
    font-size: 10px;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    animation: mulShdSpin 1.3s infinite linear;
    transform: translateZ(0);
    left: 50%;
    top: 50%;
}
  
@keyframes mulShdSpin {
    0%,
    100% {
        box-shadow: 0 -3em 0 0.2em, 
        2em -2em 0 0em, 3em 0 0 -1em, 
        2em 2em 0 -1em, 0 3em 0 -1em, 
        -2em 2em 0 -1em, -3em 0 0 -1em, 
        -2em -2em 0 0;
    }
    12.5% {
        box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 
        3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, 
        -2em 2em 0 -1em, -3em 0 0 -1em, 
        -2em -2em 0 -1em;
    }
    25% {
        box-shadow: 0 -3em 0 -0.5em, 
        2em -2em 0 0, 3em 0 0 0.2em, 
        2em 2em 0 0, 0 3em 0 -1em, 
        -2em 2em 0 -1em, -3em 0 0 -1em, 
        -2em -2em 0 -1em;
    }
    37.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, 
        -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, 
        -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
        box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em,
        3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, 
        -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
        box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 
        3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
        -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
        box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 
        3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, 
        -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
}


.modal-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
}

@media (max-width: 1280px) {
    .container {
        justify-content:  space-between;
    }
    .plus-button:first-child {
        margin-right: 0;
    }
}