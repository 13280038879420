.container {
    
}

.header {
    /* border: 1px solid blue; */
    padding: 6px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    background: #fff;
    box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1);

    /* background: linear-gradient(to bottom,#fff 0,#fff 98%,#b7b7b7 100%); */
}
.header-top {
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header-top > img {
    /* padding: 0 13.125px; */
    cursor: pointer;
}
.header-top-action {
    color: #201e1d;
}
.header-top-action:hover {
    cursor: pointer;
}
.header-top-menu {
    display: none;
}


.header-bottom {
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
}

.header-bottom-action {
    /* width: 100%; */
    padding: 0 13.125px;
    display: flex;
    margin-top: 16px;
}
.action-item {
    font-size: 16px;
    font-weight: 700;
    color: #201e1d;
    display: flex;
    justify-content: center;
    flex: 1;
}

.action-item-group {
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
}
.action-item-group:hover .action-item-group-label{
    color: #BC0021;
}
.action-item-group:hover .dropdown-content{
    display: block;
}
.action-item-group > img {
    margin-right: 10px;
}
.action-item-group-label-active {
    color: #BC0021;
}
.action-item-active-border {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -21px;
    height: 3px;
    background: #BC0021
}

.dropdown-content {
    display: none;
    position: absolute;
    min-width: 290px;
    left: 0;
    top: calc(100%);
    background: #fff;
    padding: 5px 0;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,.15);
    z-index: 999;
}
.dropdown-item {
    display: flex;
    align-items: center;
    text-decoration: none;
    list-style: none; 
    padding: 3px 20px;
    cursor: pointer;
}
.dropdown-item:hover {
    background: #f5f5f5;
    color: #BC0021;
}
.dropdown-icon {
    margin-right: 10px;
}


.body {
    width: 100%;
    /* max-width: 1050px; */
    margin: 20px auto;
}


.footer {
    display: flex;
    flex-direction: column;
    max-width: 1050px;
    margin: 0 auto;
    padding: 10px 0;
    border-top: 1px solid #999;
    font-size: 12px;
    line-height: 18px;
    color: #333333;
}

.footer-section {
    display: flex;
    flex-direction: column;
    padding: 0 13.125px;
}

.footer-title {
    font-weight: 700;
}
.footer-content {
    margin: 10px 0 15px;
}

.copy-right {
    border-top: 1px solid #999;
    padding: 10px 0 5px;
    /* text-align: justify; */
    margin: 0 13.125px;
}
.copy-right-img {
    padding: 3px 0 0 5px;
    float: right;
    border-width: 0;
}
.copy-right-a {
    text-decoration: none;
    color: #201e1d;
}
.copy-right-a:hover {
    color: #BC0021;
}

.menu-container {
    display: none;
}
.menu-mask {
    display: none;
}

@media (max-width: 1280px)  {
    .header-top {
        width: 100%;
    }
    .header-bottom {
        width: 100%;
    }
    .footer {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .header-top >img {
        width: 55%;
    }
    .header-top-menu {
        display: flex;
    }
    .header-top-menu >button {
        background: #fff;
        border: none;
        cursor: pointer;
        padding: 0;
        width: 24px;
        height: 24px;
    }

    .header-bottom {
        display: none;
    }
    .menu-container {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 70%;
        transform: translateX(-100%);
        background: #fff;
        z-index: 999;
        box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1);
        color: #333333;
    }
    .menu-mask {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.5;
        background: #fff;
    }
    .menu-header {
        height: 64px;
        box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1);
        display: flex;
        align-items: center;
        padding-left: 24px;
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
    }
    .menu-body {
        margin-top: 36px;
        display: grid;
        grid-template-columns: 1fr;
        /* grid-template-rows: 1fr 1fr 1fr; */
        gap: 24px;
    }
    .menu-item {
        margin: 0 8px;
    }
    .menu-item-header {
        display: flex;
        align-items: center;
        line-height: 18px;
        font-size: 16px;
        font-weight: 500;
        padding-left: 8px;
    }
    .menu-item-header >img {
        margin-right: 12px;
    }
    .menu-item-body {
        display: flex;
        flex-direction: column;
    }
    .menu-item-body-item {
        font-size: 14px;
        display: flex;
        align-items: center;
        margin-top: 12px;
        padding: 6px 0 6px 8px;
        cursor: pointer;
    }
    .menu-item-body-item-active {
        background: #EBEBEB;
        border-radius: 4px;
    }
    .menu-item-body-item >img {
        margin: 0 14px 0 2px;
        width: 20px;
        height: 20px;
    }

    .footer-margin {
        margin-bottom: 80px;
    }
}