.container {
    display: flex;
    flex-direction: column;
}
.title {
    color: #000000;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
    margin-bottom: 43.5px;
}

.info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    color: #000000;
}
.info-item {
    display: flex;
    flex-direction: column;
}
.info-item-header {
    display: flex;
    align-items: center;
}
.info-header-img-container {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-right: 8px;
}
.info-item-tips-container {
    display: flex;
    flex-direction: column;
}
.tips-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
}
.tips-value {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    margin-top: 8px;
}


.info-item-body {
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    margin-top: 12px;
}
.body-header {
    display: grid;
    grid-template-columns: 70% 30%;
    font-weight: 500; 
    color: #BC0021;
}
.body-item {
    display: grid;
    grid-template-columns: 80% 20%;
    font-size: 12px;
    line-height: 16px;
    background: #F3F2F2;
    padding: 3px 0;
}
.body-item-left {
    display: flex;
    align-items: center;
}
.body-item-value {
    display: flex;
    align-items: center;
    justify-content: center;
}

.indicator {
    margin-left: 8px;
    flex: 1;
    position: relative;
}
.indicator-background {
    background: #D0D0D0;
    height: 5px;
    border-radius: 5px;
}
.indicator-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    border-radius: 5px;
}
.indicator-tips {
    position: absolute;
    height: 25px;
    width: 50px;
    bottom: calc(100% + 16px);
    left: 0;
    border-radius: 6px;
    transform: translateX(-25px);
}
.indicator-tips-arrows {
    position: absolute;
    width: 10px;
    height: 10px;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
}
.indicator-tips-circle {
    position: absolute;
    height: 6px;
    width: 6px;
    background: #000000;    
    border-radius: 100%;
    left: 50%;
    transform: translateX(-50%);
    bottom: -24px;
    border: 3px solid #FFFFFF;
}

@media (max-width: 1280px) {
    .info {
        grid-template-columns: 1fr;
    }
    .info-full {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .body-header-full {
        grid-template-columns: 80% 20%;
    }
    .body-header-full >span:last-child {
        display: flex;
        justify-content: center;
    }
}
@media (max-width: 767px) {
    .title {
        margin-bottom: 24px;
    }
    .info {
        gap: 24px;
    }
    .info-item {
        padding: 36px 10px  16px 10px;
        background: #FFFFFF;
        box-shadow: 0px 4px 30px rgba(16, 22, 32, 0.15);
        border-radius: 8px;
    }
}