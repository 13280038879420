.container {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 48px;
}
.item {
    position: relative;
    flex-shrink: 0;
    overflow: hidden;
    /* width: calc(50% - 12px); */
}
.item-right {
    height: 100%;
}

.box-container {
    display: flex;
    flex-direction: column;
}
.box-title {
    color: #000000;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
}
.box-info {
    display: flex;
    align-items: center;
    margin-top: 12px;
}
.box-subtitle {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    margin-right: 20px;
}

.box-value {
    font-weight: 700;
    font-size: 24px;
    line-height: 26px;
}

.box-table {
    position: relative;
    padding: 12px 0 0 18px;
    margin-top: 12px;
    border-top: 1px solid #000000;
    display: flex;
    flex-direction: column;
}
.box-table-title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    margin-left: 20px;
}
.box-table-info {
    display: flex;
    margin-top: 12px;
}
.table-chart-container {
    flex: 1;
    padding-left: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 4px;
    position: relative;
}
.table-label {
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: #497CDE;
}
.table-item {
    height: 0;
    padding-bottom: 100%;
    background: #DAE6FD;
    border-radius: 6px;
    position: relative;
}
.table-item > span {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.table-right-left {
    display: grid;
    gap: 4px;
    margin-right: 4px;
    margin-top: 20px;
    position: absolute;
    height: 100%;
}
.table-right-left > span {
    writing-mode: vertical-rl;
    font-size: 12px;
    line-height: 14px;
    color: #497CDE;
}
.table-right-title {
    margin-top: 20px;
    position: absolute;
    left: -20px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    writing-mode: vertical-rl;
}

.table-weight-container {
    margin: 20px 0 0 10px;
    width: 30%;
    flex-shrink: 0;
}
.table-weight-title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
}
.table-weight-item {
    margin-top: 16px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
}
.table-weight-item:first-child {
    margin-top: 8px
}
.table-weight-item > span {
    margin-left: 16px;
}
.table-weight-box {
    width: 18px;
    height: 18px;
    border-radius: 4px;
}


.steps-container {
    height: 100%;
    position: relative;
}
.steps-item {

}

.steps-action {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: end;
}
.steps-action-circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #D7D4D4;
    margin-right: 16px;
    cursor: pointer;
}

.interest-risk {
    display: flex;
    flex-direction: column;
}
.interest-risk-item {
    display: grid;
    background: #F3F2F2;
    padding: 8px 10px;
    margin-bottom: 8px;
    font-size: 14px;
    grid-template-columns: 5fr 2.5fr 2.5fr;
    margin-left: -18px;
}
.interest-risk-item-header {
    color: #BC0021;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    background: #fff;
}
.interest-risk-item-last {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.credit-quality {
    display: flex;
    flex-direction: column;
}
.credit-quality-container {
    padding: 12px 0 0 0;
    margin-top: 12px;
    border-top: 1px solid #000000;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}
.credit-quality-box {
    display: flex;
    flex-direction: column;
}
.credit-quality-box-item {
    background: #F3F2F2;
    padding: 8px 10px;
    margin-bottom: 8px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.credit-quality-box-header {
    background: #fff;
    color: #BC0021;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
}

@media (max-width: 1280px) {
    .container-full {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
    }
    .item-full {
        margin-bottom: 24px;
    }
    .box-table-info-first {
        transform: translateX(16px);
    }
}