.group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-top: -14px;
}
.container {
    display: flex;
    /* gap: 6px; */
}
.container:first-child {
    margin-left: auto
}

.item {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height:16px;
    color: #28303F;
    margin-left: 6px;
}
.item> img {
    width: 20px;
    height: 20px;
}

.checkbox {
    width: 18px;
    height: 18px;
    margin: 0;
    cursor: pointer;
    border-radius: 2px;
    background: #fff;
}

@media (max-width: 767px) {
    .group {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 16px;
        margin-top: 0;
    }
    .container {
        width: 100%;
        display: flex;
        align-items: center;
        /* justify-content: space-between; */
    }
    .item:first-child {
        margin-left: 0;
    }
    .item:last-child {
        margin-left: auto;
    }
}