.container {
    position: relative;
    flex-shrink: 0;
    width: calc(50% - 6px);
    overflow: hidden;
}
.title {
    color: #000000;
    font-size: 20px;
    line-height: 22px;
    font-weight: 700;
}

.tabs-container { 
    position: absolute;
    left: 0; 
    right: 0;
    bottom: 0;
    top: 34px;
    padding-bottom: 24px
}
.tabs-item {
    top: 34px;
    width: 100%;
    bottom: 0;
    position: absolute;
    background: #fff;
}

.tabs-action {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tabs-action-circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #D7D4D4;
    margin-right: 16px;
    cursor: pointer;
}


.regions-box {
    height: 100%;
    display: flex;
}
.regions-box-img {
    width: 120px;
}
.regions-box-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    transform: translateY(-34px);
}
.regions-box-info-header {
    background: #fff;
    color: #BC0021;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.regions-box-info-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    line-height:16px;
    font-weight: 600;
    margin: 16px 0 8px 0;
    padding: 0 10px 0 38px;
}

.regions-box-info-item {
    background: #F3F2F2;
    padding: 8px 10px;
    margin-bottom: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.regions-box-info-item-left {
    display: flex;
    align-items: center;
}
.regions-box-info-item-box {
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin-right: 10px;
}

@media (max-width: 1280px) {
    .container {
        width: auto;
        min-height: 300px;
    }
    .regions-box-img {
        width: 160px;
        margin-right: 10%;
    }
}

@media (max-width: 767px) {
    .container {
        margin-top: 24px;
    }
    .regions-box-img  {
        margin-right: 0;
        width: 130px;
    }
}