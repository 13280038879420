.container {
    height: 200px;
    display: flex;
    flex-direction: column;
}

.button-group {
    margin-right: 8px;
    display: flex;
    margin-bottom: 8px;
}
.button {
    background: #fff;
    font-size: 12px;
    width: 100%;
    color: #5e5e5e;
    cursor: pointer;
    height: 26px;
    outline: none;
    border: 1px solid #1e1e1e;
}
.button:hover {
    background: #5e5e5e;
    color: #fff;
}
.button-left {
    border-right: none;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
}
.button-right {
    border-left: none;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
}
.button-action {
    background: #333;
    color: #fff;
}
.button-action:hover {
    background: #333;
}

.pie-chart {
    flex: 1;
}

.bar-chart-container {
    flex: 1;
    padding-right: 8px;
}

.tooltip {
    display: flex;
    flex-direction: column;
}
.tooltip-title {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
}

.tooltip-info {
    width: 100px;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #9e9e9e;
}
.tooltip-info-item {
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.tooltip-info-item:last-child { 
    margin-bottom: 0;
}