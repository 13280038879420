.container {
    display: flex;
    flex-direction: column;
    margin: 40px auto 0 auto;
    max-width: 1050px;
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%
}
.header-title {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: rgba(40, 48, 63, 0.7);
    margin-bottom: 12px;
    /* text-align: center; */
}

.input-container {
    width: 50%;
    position: relative;
    border-radius: 65px;
}
.input-container::after {
    content: "";
    position: absolute;
    height: 17px;
    width: 17px;
    left: 12px;
    bottom: 50%;
    transform: translateY(50%);
    z-index: 1;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAAXNSR0IArs4c6QAAAbNJREFUOE+1001Kw0AUAOCZpI2ILQoluFBLRVyIIv6fQMRrZFXKTEt7gpAbhCTTklVPoRtBwY0oKC5cia4Ef1pK1UWxSd+MjBhRG9qNZvmSfO9n3mD0Bw/+AwPFIr7vJ4MgmFYUZRxjrAohOkKIBqW0FZe0D7FtO6tp2qIQ4gEh1OKcA8Y4hTGeEkK8NJvNS8uy+HfsByKBRCIxH4bheaVSef6dtVarLQHAGKX0NBaRLQDAdrfbPYkDop9c193QNK2dz+dvo9hXJa7rzsoZEEIuBw27Xq9PdDqdVULIUR/CGFuRMyCE3A07McbYbqPROLQsK5DfflVSrVbXAeChWCzeD0Mcx9lJp9PHhmG8/UAYYwtCCKCUXg9CTNPUdF3fppTu97XjeV4GY7z8vdc4zHGcOTk7SulFHyIDnuetqaoaFAqFqzjgM9EOAOyVSqXXWMQ0TUXX9U3ZViqVujEM42NXZAuZTGZGVdUtzrkKAAflcvkpFomCvu/P9Xq9LEJoBAC4oigJhNAj5/yGcz6aTCa3wjA8i6CBF1BWkMvllOgUoiS2bU+22+1mtP7/d4uH7cnv9++5/MASOJ/zQgAAAABJRU5ErkJggg==);
    background-size: cover;
}
.input-container-close {
    position: absolute;
    height: 14px;
    width: 14px;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    background-size: cover;
    cursor: pointer;
}
.input {
    width: calc(100% - 75px);
    height: 100%;
    height: 44px;
    border: 1px solid transparent;
    border-radius: 65px;
    outline: 0 none;
    padding: 0 37px 0 38px;
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(65, 75, 99, 0.1);
}
.input:hover, .input:active, .input:focus{
    border: 1px solid #C42441;
}

.body {
    padding: 0 13.125px;
}
.top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    margin-top: 40px;
}
.top-item {
    display: flex;
    flex-direction: column;
}
.top-item-category {
    grid-column-start: span 2;
}
.top-item-tips {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #28303F;
}
.top-item-tips > img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
.top-item-select {
    margin-top: 6px;
}

.more {
    margin-top: 40px;
    top: -9999px;
    display: grid;
    grid-template-columns: calc(50% - 12px) calc(50% - 12px);
    gap: 24px;
    justify-content: space-between;
    /* gap: 26.25px; */
}
.more-item {
    /* width: calc(50% - 12px); */
    flex-shrink: 0;
}
.more-item-header {
    display: flex;
    align-items: center;
    gap: 6px;
    font-size: 14px;
    line-height: 16px;
    color: #28303F;
}
.more-item-header > img {
    width: 20px;
    height: 20px;
}
.more-item-left {

}

.more-item-right {
    display: grid;
    grid-template-columns: 1fr 1fr;
    font-size: 14px;
    line-height: 16px;
    gap: 24px;
}
.right-item {
    display: flex;
    flex-direction: column;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    padding: 10px 0 10px 10px;
    color: #28303F;
}
.right-item-body {
    margin: 10px 0;
}
.right-item-body-group {
    margin: 0 45px 20px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 1px;
    position: relative;
}
.group-right-info {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: 45px;
    display: flex;
}
.group-right-info-left {
    display: grid;
    grid-template-rows: 1fr 1fr 1f;
    gap: 1px;
    margin-right: 4px;
}
.group-right-info-left >span {
    writing-mode: vertical-rl;
    font-size: 11px;
    color: #FFB23E;
}
.group-right-info-left >span:first-child {
    color: #F75555;
}
.group-right-info-left >span:last-child {
    color: #49C163;
}
.group-right-info-right >span{
    writing-mode: vertical-rl;
    line-height: 1.55;
    font-size: 12px;
    font-weight: 400;
    color: #28303F;
}

.group-bottom-info {
    position: absolute;
    margin-top: 5px;
    top: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 33.33%);
    gap: 1px;
    font-size: 11px;
    color: #FFB23E;
}
.group-bottom-info >span:first-child {
    color: #49C163;
}
.group-bottom-info >span:last-child {
    color: #F75555;
}
.group-item {
    height: 0;
    padding-bottom: 100%;
    cursor: pointer;
}


.right-item-bold {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.55;
    color: #28303F;
}

.action {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.action-button {
    background: #bc0021;
    color: #fff;
    outline: none;
    border: 1px solid #999999;
    padding: 4px 16px;
    border-radius: 15px;
    cursor: pointer;
    font-family: "Inter";
}
.action-button:hover {
    background: #d40a1b;
}
.action-button-ch {
    font-family: "Noto Sans HK";
}

@media (max-width: 1280px) {
    .top {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .top-item-category {
        grid-column-start: span 3;
    }
}
@media (max-width: 767px) {
    .input-container {
        width: 90%;
    }
    .top {
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);    
    }
    .top-item-category {
        grid-column-start: span 2;
    }
    .more {
        grid-template-columns: 1fr;
    }
    .right-item-title {
        font-size: 12px;
    }
    .top-item-select {

        /* width: 50%; */
    }
}